export const PERIOD_SINCE = 'since';
export const PERIOD_TO = 'to';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATETIME_FORMAT = 'yyyy-MM-DD HH:mm:ss';

export const ACCOUNT_STATUS = {
  UNSTARTED: 'unstarted',
  ACTIVE: 'active',
  EXPIRED: 'expired',
  PAST_DUE: 'past_due',
};
